<template>
  <div>
    <div class="table-head">
      <div class="table-search">
        <el-space>
          <div>
            <el-button type="primary" @click="syncGroup" v-if="syncTagStatus!=1&&syncInfo.status!=1">同步标签组及标签名称</el-button>
            <el-button type="primary" :disabled='syncStatus' v-else>同步中</el-button>
            <span style="margin-left:5px">最近同步时间:{{syncInfo.time}}</span>
          </div>
        </el-space>
        <div>
            <el-input v-model="searchOption.key" placeholder="请输入标签组" @change="searchAction">
                <template #prefix>
                    <el-icon class="el-input__icon"><Search /></el-icon>
                </template>
            </el-input>
        </div>
      </div>
    </div>
    <div class="line-height"></div>
    <div class="table-box">
      <el-table :data="tableData" style="width: 100%" border>
          <el-table-column prop="TagGroupName" label="标签组名称"></el-table-column>
          <el-table-column prop="TagCount" label="组内标签数量">
            <template #default="scope">
                <div class="cell-wrap">
                    <div>{{ scope.row.TagCount }}</div>
                </div>
            </template>
          </el-table-column>
          <el-table-column prop="CustomerCount" label="组内客户总数"></el-table-column>
          <el-table-column prop="SyncStatus" label="组内客户同步状态">
            <template #default="scope">
                <div >
                    <div v-if="scope.row.SyncStatus==2">全部成功</div>
                    <div v-else-if="scope.row.SyncStatus==11">部分成功</div>
                    <div v-else-if="scope.row.SyncStatus==1">同步中({{scope.row.SyncProcess}}%)</div>
                    <div v-else>未同步</div>
                </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="220">
            <template #default="scope">
                <el-button type="primary" @click="checkDetail(scope.row)">查看</el-button>
                <el-button type="primary" @click="syncCustom(scope.row)" v-if="scope.row.SyncStatus!=1">同步客户</el-button >
                <el-button type="primary" @click="NoSyncCustom(scope.row)" v-else>取消同步</el-button >
            </template>
        </el-table-column>
      </el-table>
        <TableFoot
            :pagination="pageObj"
            @changePage="changePage"
            @changeSize="changeSize"
        />
    </div>
  </div>
</template>
<script>
import { reactive,ref,onMounted} from "vue";
import { useRoute, useRouter } from "vue-router";
import TableFoot from "@/components/TableFoot";
import { ElMessageBox,ElMessage } from 'element-plus';
import { Search} from '@element-plus/icons';
import {CorpTagGetGroupStats,CorpTagSyncTag,CorpTagSyncCustomerByTagGroup,CorpTagCancelJob,CorpTagGetSyncTagResult} from "@/helper/group.js"
import Tool from '@/assets/js/tools'
import { useStore } from "vuex";
export default {
  name: "SyncGroupList",
  components: {
      Search,TableFoot
  },
  beforeRouteEnter(to, from, next){
        next(vm=>{
            if(from.path == '/syncgroupdetail'){
                to.meta.getStore = true
            }
        })
    },
  beforeRouteLeave(to, from, next){
        this.$store.commit("SyncGroupList/setData",{
            pageObj:this.pageObj,
            searchOption:this.searchOption,
        })
        next()
  },
  setup() {
    const router = useRouter();
    const searchOption=reactive({
        key:""
    })
    const tableData = ref([]);
    const syncInfo=reactive({
        status:0,
        time:""
    })
    const store = useStore()
    const route = useRoute()
    onMounted(()=>{
        resetStore()
        searchAction()
    })
    function resetStore(){
        if(route.meta.getStore&& store.state.SyncGroupList.pageObj&&store.state.SyncGroupList.searchOption){
            pageObj.TotalRow= store.state.SyncGroupList.pageObj.TotalRow,
            pageObj.PageIndex= store.state.SyncGroupList.pageObj.PageIndex,
            pageObj.PageSize=store.state.SyncGroupList.pageObj.PageSize,
            searchOption.key = store.state.SyncGroupList.searchOption.key
        }
    }

    /******************************搜索**********************************/
    function searchAction(){
        let param={
            PageNum:pageObj.PageIndex,
            PageSize:pageObj.PageSize,
            TagName:searchOption.key
        }
        CorpTagGetGroupStats(param).then(res=>{
            console.log(res);
            tableData.value=res.List
            pageObj.TotalRow=res.TotalCount
            syncInfo.status=res.SyncTagStatus
            syncInfo.time=res.LastSyncTime
            if(syncInfo.status==1){
               syncStatus.value=true
            }
        })
    
    }
    const syncStatus=ref(false)
    const syncTagStatus=ref(0)
    //同步组和名称
    function syncGroup(){
        CorpTagSyncTag().then(res=>{
            CorpTagGetSyncTagResult({JobGUID:res.JobGUID}).then(res=>{
                console.log(res);
                syncTagStatus.value=res.SyncTagStatus
                if(syncTagStatus.value==2){
                     searchAction()
                }else{
                    syncStatus.value=true
                    ElMessage({
                        type: "success",
                        message: "同步中...",
                    });
                }
            })
            
        })
    }
    //取消同步  
    function NoSyncCustom(row){
        let param={
            Type:1,
            TagSerialNo:row.TagGroupSerialNo,
        }
        ElMessageBox.confirm(`取消同步后会终止同步操作，导致部分用户无法完成同步，但对已经同步成功的用户不造成影响，您确定要这样做吗？`, "确认提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        }).then(() => {
            CorpTagCancelJob(param).then(res=>{
                searchAction();
            })
        }).catch(() => {
            ElMessage({
                type: "info",
                message: "已取消",
            });
        });

    }
    //查看
    function checkDetail(item){
        router.push({ name: "SyncGroupDetail", query: {TagGroupSerialNo:item.TagGroupSerialNo} });
    }

    //同步
    function syncCustom(row){
        CorpTagSyncCustomerByTagGroup({TagGroupSerialNo:row.TagGroupSerialNo}).then(res=>{
            searchAction()
        })
    }

    /****************TableFoot*******************/
    const pageObj = reactive({
      TotalRow: 0,
      PageIndex: 1,
      PageSize: 10,
    });
    //页码改变
    function changePage(index) {
      pageObj.PageIndex = index;
      searchAction();
    }
    function changeSize(size) {
      pageObj.PageSize = size;
      pageObj.PageIndex = 1;
      searchAction();
    }
    return {
        searchOption,
        tableData,
        pageObj,
        syncInfo,
        syncStatus,
        syncTagStatus,
        changePage,
        syncGroup,
        changeSize,
        searchAction,
        checkDetail,
        syncCustom,
        NoSyncCustom
    };
  },
};
</script>
<style lang="scss" scoped>
:deep().table-search span{
    margin-right: 0;
}
.cell-wrap{
    color:$color-common;
}
</style>